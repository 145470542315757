import React from "react";

import Layout from "../templates/layout";
import { Link } from 'gatsby'
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className='mt-32 text-center'>
        <h2 className="p-3 my-8 text-4xl font-bold">
          Page Not found
        </h2>
        <Link to='/' className='px-4 py-2 font-semibold border-2 border-flyer-blue hover:bg-flyer-blue hover:text-white text-flyer-blue'>Go back to Home</Link>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
